import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Menu } from "antd";

import { AppStore } from "../../../stores";

import Logo from "../../../assets/media/shared/Logo";
import LogoutIcon from "../../../ui/icons/LogoutIcon";

import { PLATFORM_URL } from "../../../shared/constants";
import useIsMobile from "../../../shared/hooks/useIsMobile";

import "./style.scss";

const Sidebar = () => {
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const isMobile = useIsMobile();
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (AppStore.currentPage >= 2 && AppStore.currentPage <= 6) {
      setOpenKeys(["tutorial"]);
    } else {
      setOpenKeys([]);
    }
  }, [AppStore.currentPage]);

  useEffect(() => {
    if (AppStore.currentPage === 1) {
      return setSelectedKeys(["introduction"]);
    } else if (AppStore.currentPage === 2) {
      return setSelectedKeys(["tutorial_1"]);
    } else if (AppStore.currentPage === 3) {
      return setSelectedKeys(["tutorial_2"]);
    } else if (AppStore.currentPage === 4) {
      return setSelectedKeys(["tutorial_3"]);
    } else if (AppStore.currentPage === 5) {
      return setSelectedKeys(["tutorial_4"]);
    } else if (AppStore.currentPage === 6) {
      return setSelectedKeys(["tutorial_5"]);
    } else if (AppStore.currentPage === 7) {
      return setSelectedKeys(["test"]);
    }
  }, [AppStore.currentPage]);

  const items = [
    {
      key: "introduction",
      label: "Введение",
    },
    {
      key: "tutorial",
      disabled: AppStore.currentProgress == 1,
      children: [
        {
          label: "Задание",
          key: "tutorial_1",
          disabled: AppStore.currentProgress < 2,
        },
        {
          label: "Расчёт CAPEX",
          key: "tutorial_2",
          disabled: AppStore.currentProgress < 3,
        },
        {
          label: "Расчёт доходов",
          key: "tutorial_3",
          disabled: AppStore.currentProgress < 4,
        },
        {
          label: "Расчёт OPEX",
          key: "tutorial_4",
          disabled: AppStore.currentProgress < 5,
        },
        {
          label: "Показатели эффективности",
          key: "tutorial_5",
          disabled: AppStore.currentProgress < 6,
        },
      ],
      label: "Обучение",
    },
    {
      key: "test",
      label: "Тест",
      disabled: AppStore.currentProgress < 7,
    },
  ];

  const onClick = (e) => {
    if (e.key != selectedKeys[0]) {
      setSelectedKeys([e.key]);
      let navigateTo = null;
      if (e.key === "introduction") {
        navigateTo = 1;
      } else if (e.key === "tutorial_1") {
        navigateTo = 2;
      } else if (e.key === "tutorial_2") {
        navigateTo = 3;
      } else if (e.key === "tutorial_3") {
        navigateTo = 4;
      } else if (e.key === "tutorial_4") {
        navigateTo = 5;
      } else if (e.key === "tutorial_5") {
        navigateTo = 6;
      } else if (e.key === "test") {
        navigateTo = 7;
      }
      AppStore.navigate(navigateTo);
      setOpened(false);
    }
  };

  return (
    <aside className={`sidebar${isMobile && opened ? " opened" : ""}`}>
      <div className="sidebar__header">
        <Logo className="sidebar__logo" />
        {isMobile && (
          <button
            className="sibebar__header-button"
            onClick={() => setOpened((prev) => !prev)}
          >
            {opened ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 18L18 2M2 2L18 18"
                  stroke="#283A97"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="28"
                height="20"
                viewBox="0 0 28 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 2H26M2 10H26M2 18H26"
                  stroke="#283A97"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </button>
        )}
      </div>
      {isMobile && opened ? (
        <>
          <Menu
            defaultSelectedKeys={["introduction"]}
            mode="inline"
            onClick={onClick}
            onOpenChange={(openKeys) => setOpenKeys(openKeys)}
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            items={items}
          />

          <div className="sidebar__footer">
            <button
              className="sidebar__back-button"
              onClick={() => (location.href = PLATFORM_URL)}
            >
              <LogoutIcon className="sidebar__back-button-icon" />
              <p className="sidebar__back-button-text">
                Вернуться на HR-платформу
              </p>
            </button>
          </div>
        </>
      ) : (
        !isMobile && (
          <>
            <Menu
              defaultSelectedKeys={["introduction"]}
              mode="inline"
              onClick={onClick}
              onOpenChange={(openKeys) => setOpenKeys(openKeys)}
              selectedKeys={selectedKeys}
              openKeys={openKeys}
              items={items}
            />

            <div className="sidebar__footer">
              <button
                className="sidebar__back-button"
                onClick={() => (location.href = PLATFORM_URL)}
              >
                <LogoutIcon className="sidebar__back-button-icon" />
                <p className="sidebar__back-button-text">
                  Вернуться на HR-платформу
                </p>
              </button>
            </div>
          </>
        )
      )}
    </aside>
  );
};

export default observer(Sidebar);
