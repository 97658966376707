import React from "react";
import { observer } from "mobx-react-lite";

import { AppStore } from "../../../stores";

import "./style.scss";

const IntroductionPage = () => {
  return (
    <>
      <h1 className="title">Добро пожаловать!</h1>
      <p className="text">
        В данном симуляторе вы изучите то, как правильно находить самую выгодную
        для инвестирования технологию. В ходе нашей симуляции вы сможете
        ознакомиться с такими ключевыми показателями, как: CAPEX, OPEX, NPV,
        EBITDA, PBP, ROI, пройти тест и подтвердить свои знания. Начнем с
        ознакомительного видео:
      </p>
      <iframe
        width="100%"
        height="480"
        className="introduction__video"
        src="https://www.youtube-nocookie.com/embed/bOHQFbPeSms?modestbranding=1&showinfo=0&rel=0"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <div className="page__footer">
        <div></div>
        <button
          className="button"
          onClick={() => {
            AppStore.navigate(2);
          }}
        >
          Далее
        </button>
      </div>
    </>
  );
};

export default observer(IntroductionPage);
