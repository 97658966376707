import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { FloatButton, Input, Modal, Select, notification } from "antd";

import nunjucks from "nunjucks";

import api from "../../../api";
import { AppStore } from "../../../stores";
import { Loading } from "../../../ui";
import prettierContentNums from "../../../shared/utils/prettierContentNums";

import "./style.scss";
import getNumber from "../../../shared/utils/getNumber";
import TermsModal from "../../components/TermsModal";

const TestPage = () => {
  const [selectError, setSelectError] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (AppStore.test == null) {
      setLoading(true);
      api
        .get_test_case()
        .then(({ data }) => {
          AppStore.setTest(data);
          let result = [];
          for (let answer of data.technologies) {
            result = [
              ...result,
              { id: answer.id, EBITDA: "", NPV: "", ROI: "", PBP: "" },
            ];
          }
          AppStore.setAnswers(result);

          AppStore.setContent(
            prettierContentNums(
              nunjucks.renderString(AppStore.test.case.template.content, {
                ...AppStore.test.case,
                ...AppStore.test,
              })
            )
          );
        })
        .catch((e) => {
          notification.error({
            message: "Произошла ошибка!",
            description:
              "Произошла ошибка при получении теста, проверьте соединение с интернетом и попробуйте снова.",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (AppStore.content.length > 0) {
      const image = document.querySelector(".test__image");

      if (image) {
        const imageParent = image.parentElement;

        imageParent.style["position"] = "relative";

        const logisticsText = document.createElement("span");
        logisticsText.innerText = `Логистика - ${AppStore.test.case.logistics_cost} руб./кг.`;
        logisticsText.style["color"] = "#FF0000";
        logisticsText.style["font-size"] = "18px";
        logisticsText.style["font-weight"] = "600";
        logisticsText.style["position"] = "absolute";
        logisticsText.style["top"] = "13.5%";
        logisticsText.style["left"] = "8.8%";
        imageParent.appendChild(logisticsText);

        const islandVolumeText = document.createElement("span");
        islandVolumeText.innerText = `${AppStore.test.case.sales_increase.toLocaleString()} кг`;
        islandVolumeText.style["font-size"] = "17px";
        islandVolumeText.style["font-weight"] = "600";
        islandVolumeText.style["position"] = "absolute";
        islandVolumeText.style["top"] = "42.5%";
        islandVolumeText.style["left"] = "2.8%";
        imageParent.appendChild(islandVolumeText);

        const islandPriceText = document.createElement("span");
        islandPriceText.innerText = `${AppStore.test.case.price_island} руб./кг.`;
        islandPriceText.style["color"] = "#92D050";
        islandPriceText.style["font-size"] = "17px";
        islandPriceText.style["font-weight"] = "600";
        islandPriceText.style["position"] = "absolute";
        islandPriceText.style["top"] = "47.5%";
        islandPriceText.style["left"] = "2.8%";
        imageParent.appendChild(islandPriceText);

        const mainlandVolumeText = document.createElement("span");
        mainlandVolumeText.innerText = `${AppStore.test.case.sales_mainland.toLocaleString()} кг`;
        mainlandVolumeText.style["font-size"] = "17px";
        mainlandVolumeText.style["font-weight"] = "600";
        mainlandVolumeText.style["position"] = "absolute";
        mainlandVolumeText.style["top"] = "61.5%";
        mainlandVolumeText.style["left"] = "74.8%";
        imageParent.appendChild(mainlandVolumeText);

        const mainlandPriceText = document.createElement("span");
        mainlandPriceText.innerText = `${AppStore.test.case.price_mainland} руб./кг.`;
        mainlandPriceText.style["color"] = "#92D050";
        mainlandPriceText.style["font-size"] = "17px";
        mainlandPriceText.style["font-weight"] = "600";
        mainlandPriceText.style["position"] = "absolute";
        mainlandPriceText.style["top"] = "66%";
        mainlandPriceText.style["left"] = "74.8%";
        imageParent.appendChild(mainlandPriceText);
      }
    }
  }, [AppStore.content]);

  if (!AppStore.test || loading) {
    return (
      <div className="test__loading">
        <Loading wrapperNeeded={false} />
      </div>
    );
  }

  const onSubmit = (event) => {
    event.preventDefault();
    setSelectError(false);
    if (AppStore.mostProfit === null) {
      setSelectError(true);
    } else {
      let parsedAnswers = AppStore.answers.map((item) => ({
        ...item,
        NPV: +item.NPV.replaceAll(" ", ""),
        EBITDA: +item.EBITDA.replaceAll(" ", ""),
        ROI: +item.ROI.replaceAll(" ", ""),
        PBP: +item.PBP.replaceAll(" ", ""),
      }));
      api
        .check_answers({
          technologies: parsedAnswers,
          most_profit: AppStore.mostProfit,
        })
        .then((response) => {
          if (response.data.passed === true) {
            AppStore.setFinished(1);
          } else {
            AppStore.setFinished(2);
          }
        })
        .catch(() => {
          notification.error({
            message: "Произошла ошибка!",
            description:
              "Проверьте соединение с интернетом и попробуйте снова.",
          });
        });
    }
  };

  return (
    <>
      <div className="test">
        <div
          className="test__content"
          dangerouslySetInnerHTML={{
            __html: AppStore.content,
          }}
        ></div>
        <form className="test__answers" onSubmit={onSubmit}>
          <h2 className="test__answers-title">Введите ответы:</h2>
          {AppStore.test.technologies.map((item, index) => {
            return (
              <div className="test__answers-item" key={index}>
                <h3 className="test__answers-item-title">{item.title}</h3>
                <div className="test__answers-input">
                  <label htmlFor="">EBITDA:</label>
                  <Input
                    required
                    placeholder="1 000 000"
                    value={
                      AppStore.answers.find((x) => x.id === item.id).EBITDA
                    }
                    onChange={(event) =>
                      AppStore.setAnswers(
                        AppStore.answers.map((x) =>
                          x.id === item.id
                            ? {
                                ...x,
                                EBITDA: getNumber(event.target.value),
                              }
                            : x
                        )
                      )
                    }
                  />
                </div>
                <div className="test__answers-input">
                  <label htmlFor="">NPV:</label>
                  <Input
                    required
                    placeholder="10 000 000"
                    value={AppStore.answers.find((x) => x.id === item.id).NPV}
                    onChange={(event) =>
                      AppStore.setAnswers(
                        AppStore.answers.map((x) =>
                          x.id === item.id
                            ? { ...x, NPV: getNumber(event.target.value) }
                            : x
                        )
                      )
                    }
                  />
                </div>
                <div className="test__answers-input">
                  <label htmlFor="">ROI:</label>
                  <Input
                    required
                    placeholder="10"
                    value={AppStore.answers.find((x) => x.id === item.id).ROI}
                    onChange={(event) =>
                      AppStore.setAnswers(
                        AppStore.answers.map((x) =>
                          x.id === item.id
                            ? { ...x, ROI: getNumber(event.target.value) }
                            : x
                        )
                      )
                    }
                  />
                </div>
                <div className="test__answers-input">
                  <label htmlFor="">PBP:</label>
                  <Input
                    required
                    placeholder="5"
                    value={AppStore.answers.find((x) => x.id === item.id).PBP}
                    onChange={(event) =>
                      AppStore.setAnswers(
                        AppStore.answers.map((x) =>
                          x.id === item.id
                            ? { ...x, PBP: getNumber(event.target.value) }
                            : x
                        )
                      )
                    }
                  />
                </div>
              </div>
            );
          })}
          <h3 className="test__answers-answer">
            Лучшая технология для инвестирования:
          </h3>
          <Select
            size="large"
            className="select"
            value={AppStore.mostProfit}
            status={selectError && "error"}
            placeholder="Выберите технологию"
            onChange={(key) => AppStore.setMostProfit(key)}
            options={AppStore.test.technologies.map((item) => {
              return {
                value: item.id,
                label: item.title,
              };
            })}
          />
          {selectError && (
            <div className="select__error">Выберите технологию</div>
          )}
          <div className="page__footer">
            <button
              className="button_outlined"
              onClick={() => AppStore.goBack()}
            >
              Назад
            </button>
            <button className="button" type="submit">
              Отправить
            </button>
          </div>
        </form>
        <TermsModal modalShow={modalShow} setModalShow={setModalShow} />
      </div>
      <FloatButton
        icon={<QuestionCircleOutlined />}
        onClick={() => setModalShow(true)}
        className="float-button"
      />
    </>
  );
};

export default observer(TestPage);
