import React from "react";
import { observer } from "mobx-react-lite";

import { AppStore } from "../../../stores";

import { PLATFORM_URL } from "../../../shared/constants";
import SuccessIcon from "../../icons/SuccessIcon";
import FailedIcon from "../../icons/FailedIcon";

import "./style.scss";

const FinishPage = () => {
  return (
    <div className="finish">
      {AppStore.finished === 1 ? (
        <>
          <SuccessIcon className="finish__icon" />
          <h1 className="finish__title">Поздравляем!</h1>
          <p className="finish__text">
            Отличная работа! Ваши знания впечатляют. Мы надеемся, что вы сможете
            применить их на практике и достичь больших успехов в своей карьере!
          </p>
        </>
      ) : (
        <>
          <FailedIcon className="finish__icon" />
          <h1 className="finish__title">Тест не пройден</h1>
          <p className="finish__text">
            Не отчаивайтесь, попробуйте повторить материал, и пройти тест заново
          </p>
        </>
      )}

      <button
        className="button finish__button"
        onClick={() => (location.href = PLATFORM_URL)}
      >
        Вернуться на HR-платформу
      </button>
    </div>
  );
};

export default observer(FinishPage);
