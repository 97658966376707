import React from "react";
import ReactDOM from "react-dom/client";
import Keycloak from "keycloak-js";
import { ConfigProvider } from "antd";

import { AUTH_URL } from "./shared/constants";

import App from "./App";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

function startApp() {
  root.render(
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#283A97",
        },
      }}
    >
      <App />
    </ConfigProvider>
  );
}

let initOptions = {
  url: AUTH_URL,
  realm: "Alabuga",
  clientId: "alb-game",
  onLoad: "login-required",
};

const keycloak = new Keycloak(initOptions);

keycloak
  .init({
    onLoad: initOptions.onLoad,
  })
  .then(() => {
    localStorage.setItem("user_token", keycloak.token);
    localStorage.setItem("refresh_token", keycloak.refreshToken);
    localStorage.setItem("user_uid", keycloak.tokenParsed.sub);

    let alreadyStartApp = false;
    const checkRefresh = () => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            if (localStorage.getItem("user_token")) {
              localStorage.setItem("user_token", keycloak.token);
              localStorage.setItem("refresh_token", keycloak.refreshToken);
            }
            localStorage.setItem("user_uid", keycloak.tokenParsed.sub);
          } else {
          }

          if (!alreadyStartApp) {
            startApp();
            alreadyStartApp = true;
          }
        })
        .catch(() => {
          if (!alreadyStartApp) {
            startApp();
            alreadyStartApp = true;
          }
        });
    };
    if (!alreadyStartApp) {
      startApp();
      alreadyStartApp = true;
    }

    setInterval(() => {
      checkRefresh();
    }, 10000);
  })
  .catch(() => {
    startApp();
  });
