import axios from "axios";

import { API_URL } from "../shared/constants";

const $api = axios.create({
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  config.headers = {
    jwt: localStorage.getItem("user_token"),
  };
  return config;
});

export default $api;
