import React from "react";
import { observer } from "mobx-react-lite";

import { AppStore } from "../../../stores";

import calculation_form from "../../../assets/media/education/calculation_form.png";

const EducationFifth = () => {
  return (
    <>
      <h1 className="title">Расчёт показателей эффективности</h1>
      <p className="text">
        Экономических показателей эффективности много, но для принятия решения о
        выгодности технологии хватит и четырех из них. Это: EBITDA, NPV, ROI и
        PBP. Рассмотрим каждый из них подробнее:
      </p>
      <p className="text">
        <strong>EBITDA</strong> (Earnings Before Interest, Taxes, Depreciation
        and Amortization) - прибыль до вычета расходов по выплате процентов,
        налогов, износа и начисленной амортизации. <br /> Рассчитывается по
        формуле: <strong>ИТОГО Доходы - ИТОГО Операционные затраты</strong>
      </p>
      <p className="text">
        <strong>NPV</strong> (Net Present Value) - Дословно переводится как
        "Чистая приведённая стоимость", это количество накопленных денег за
        определенный период
        <br />
        Формула расчёта: <strong>(EBITDA * (Кол-во лет-1)) - CAPEX</strong>
        <br />
        <br /> Необходимо не забыть вычесть из периода расчёта 1, так как обычно
        в первый год происходит строительство, и только со второго появится
        прибыль.
      </p>
      <p className="text">
        <strong>ROI</strong> (Return On Investment) - Коэффициент рентабельности
        инвестиций, который помогает рассчитать окупаемость вложений в проект.
        Позволяет определить эффективность потраченных средств. <br /> Формула
        вычисления ROI: <strong>EBITDA / CAPEX * 100%</strong> <br />{" "}
        Примечание: округляется до целого числа по правилам округления
      </p>
      <p className="text">
        <strong>PBP</strong> (Payback Period) - Срок в годах за который окупятся
        инвестиции <br /> Это год в котором NPV стал больше 0, также можно
        вычислить по формуле: <br /> <strong>(CAPEX/EBITDA) + 1</strong> (1 -
        это срок стройки) <br />
        <p style={{ marginTop: 5 }}>
          Примечание: округляется до целого числа в большую сторону
        </p>
      </p>
      <p className="text">
        Давайте рассчитаем показатели эффективности для всех линий производства
        сроком в <strong>15 лет</strong>:
      </p>
      <div className="page__list">
        <div className="page__list-item">
          <h2>Китайская</h2>
          <p className="text">
            CAPEX = 80 млн. руб. <br />
            OPEX = 31,8 млн. руб. в год <br />
            Доходы: 44,4 млн. руб. в год
          </p>
          <h3>EBITDA = 44,4 млн. руб. - 31,8 млн. руб. = 12,6 млн. руб.</h3>
          <h3>
            NPV = (12,6 млн. руб. * 14 лет) - 80 млн. руб. = 96,4 млн. руб.
          </h3>
          <h3>ROI = 12,6 млн. руб. / 80 млн. руб. * 100 = 16%</h3>
          <h3>PBP = 80 млн. руб / 12,6 млн. руб. + 1 = 8 лет</h3>
        </div>
        <div className="page__list-item">
          <h2>Голландская</h2>
          <p className="text">
            CAPEX = 120 млн. руб. <br />
            OPEX = 118,8 млн. руб. в год <br />
            Доходы: 126 млн. руб. в год
          </p>
          <h3>EBITDA = 126 млн. руб. - 118,8 млн. руб. = 7,2 млн. руб.</h3>
          <h3>
            NPV = (7,2 млн. руб. * 14 лет) - 120 млн. руб. = -19,2 млн. руб.
          </h3>
          <h3>ROI = 7,2 млн. руб. / 120 млн. руб. * 100 = 6%</h3>
          <h3>PBP = 120 млн. руб / 7,2 млн. руб. + 1 = 18 лет</h3>
        </div>
        <div className="page__list-item">
          <h2>Японская</h2>
          <p className="text">
            CAPEX = 45 млн. руб. <br />
            OPEX = 33,6 млн. руб. в год <br />
            Доходы: 39,6 млн. руб. в год
          </p>
          <h3>EBITDA = 39,6 млн. руб. - 33,6 млн. руб. = 6 млн. руб.</h3>
          <h3>NPV = (6 млн. руб. * 14 лет) - 45 млн. руб. = 39 млн. руб.</h3>
          <h3>ROI = 6 млн. руб. / 45 млн. руб. * 100 = 13%</h3>
          <h3>PBP = 45 млн. руб / 6 млн. руб. + 1 = 9 лет</h3>
        </div>
      </div>
      <h2 className="title">Подводим итоги:</h2>
      <p className="text">
        Нашей задачей было выбрать наиболее эффективную технологию по показателю
        NPV. Посмотрев на NPV у всех трех технологий видно, что самый большой
        NPV у <strong>Китайской технологии</strong>, 96,4 млн. руб. это деньги,
        которые мы бы заработали за 15 лет. К примеру, за 15 лет у Голландской
        технологии мы бы не окупились, на это понадобилось бы 18 лет.
      </p>
      <p className="text">
        <strong>
          Итог: самая выгодная для инвестирования технология - Китайская
        </strong>
        <br />
        <br />А так может выглядеть итоговый вариант формы расчёта:
      </p>
      <img src={calculation_form} alt="" className="page__image" />
      <br />
      <br />
      <p className="text">
        <strong>Пришло время проверить ваши знания!</strong>
      </p>
      <div className="page__footer">
        <button className="button_outlined" onClick={() => AppStore.goBack()}>
          Назад
        </button>
        <button className="button" onClick={() => AppStore.navigate(7)}>
          Далее
        </button>
      </div>
    </>
  );
};

export default observer(EducationFifth);
