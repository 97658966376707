import React from "react";

const LogoutIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3516 7V3.25C12.3516 2.65326 12.1074 2.08097 11.6726 1.65901C11.2379 1.23705 10.6483 1 10.0334 1H3.85163C3.23681 1 2.64717 1.23705 2.21243 1.65901C1.77768 2.08097 1.53345 2.65326 1.53345 3.25V16.75C1.53345 17.3467 1.77768 17.919 2.21243 18.341C2.64717 18.7629 3.23681 19 3.85163 19H10.0334C10.6483 19 11.2379 18.7629 11.6726 18.341C12.1074 17.919 12.3516 17.3467 12.3516 16.75V13M8.48799 7L5.39708 10M5.39708 10L8.48799 13M5.39708 10H18.5334"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
