import { Modal } from "antd";
import React from "react";

const TermsModal = ({ modalShow, setModalShow }) => {
  return (
    <Modal
      title="Термины"
      centered
      open={modalShow}
      onOk={() => setModalShow(false)}
      onCancel={() => setModalShow(false)}
      footer={[]}
      width={800}
    >
      <p className="text">
        <strong>EBITDA</strong> - прибыль до вычета процентов, налогов и
        амортизации. <br /> Рассчитывается по формуле:{" "}
        <strong>ИТОГО Доходы - ИТОГО Затраты</strong> за год
      </p>
      <p className="text">
        <strong>NPV</strong> - Количество накопленных денег на определенный год{" "}
        <br /> Формула расчёта:{" "}
        <strong>(EBITDA * (Кол-во лет-1)) - CAPEX</strong>
      </p>
      <p className="text">
        <strong>ROI</strong> - Доходность инвестиций в процентах: <br /> Формула
        вычисления ROI: <strong>EBITDA / CAPEX * 100%</strong> <br />{" "}
        Примечание: округляется до целого числа по правилам округления
      </p>
      <p className="text">
        <strong>PBP</strong> - Срок в годах за который окупятся инвестиции{" "}
        <br /> Это год в котором NPV стал больше 0, также можно вычислить по
        формуле: <strong>(CAPEX/EBITDA) + 1</strong> <br /> Примечание:
        округляется до целого числа в большую сторону
      </p>
    </Modal>
  );
};

export default TermsModal;
