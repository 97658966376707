import React from "react";
import { observer } from "mobx-react-lite";

import { AppStore } from "../../../stores";

const EducationSecond = () => {
  return (
    <>
      <h1 className="title">Расчёт капитальных затрат (CAPEX)</h1>
      <p className="text">
        <b>CAPEX</b> (CAPital EXpense) - капитальные затраты компании. Как
        правило, разовые. Это расходы на создание, покупку и модернизацию
        основных средств. Например, покупка зданий, земельных участков,
        оборудования, транспортных средств и прочее.
      </p>
      <p className="text">
        В нашем случае для расчёта CAPEX необходимо сложить стоимость
        оборудования и монтажных работ, потому что они необходимы для работы
        тепличного комплекса и покупаются один раз.
      </p>
      <p className="text">Просчитаем CAPEX для всех линий производства:</p>
      <div className="page__list">
        <div className="page__list-item">
          <h2>Китайская</h2>
          <p className="text">
            Капитальные затраты формируются 2 статьями:
            <ul>
              <li>Конструкция и оборудование - 56 млн. руб.</li>
              <li>Строительные и монтажные работы – 24 млн руб.</li>
            </ul>
          </p>
          <br />
          <h3>СAPEX = 56 млн. руб. + 24 млн. руб. = 80 млн. руб.</h3>
        </div>
        <div className="page__list-item">
          <h2>Голландская</h2>
          <p className="text">
            Капитальные затраты формируются 2 статьями:
            <ul>
              <li>Конструкция и оборудование - 84 млн руб.</li>
              <li>Строительные и монтажные работы – 36 млн руб.</li>
            </ul>
          </p>
          <br />
          <h3>СAPEX = 84 млн. руб. + 36 млн. руб. = 120 млн. руб.</h3>
        </div>
        <div className="page__list-item">
          <h2>Японская</h2>
          <p className="text">
            Капитальные затраты формируются 2 статьями:
            <ul>
              <li>Конструкция и оборудование - 30 млн. руб.</li>
              <li>Строительные и монтажные работы – 15 млн руб.</li>
            </ul>
          </p>
          <br />
          <h3>СAPEX = 30 млн. руб. + 15 млн. руб. = 45 млн. руб.</h3>
        </div>
      </div>
      <div className="page__footer">
        <button className="button_outlined" onClick={() => AppStore.goBack()}>
          Назад
        </button>
        <button className="button" onClick={() => AppStore.navigate(4)}>
          Далее
        </button>
      </div>
    </>
  );
};

export default observer(EducationSecond);
