import React from "react";
import { observer } from "mobx-react-lite";

import { AppStore } from "../../../stores";

const EducationThird = () => {
  return (
    <>
      <h1 className="title">Расчёт доходов</h1>
      <p className="text">
        Доходы - это вырученные денежные средства, с продаж готовой продукции.
        Для их расчёта необходимо вычислить объем производства, и умножить его
        на цену единицы товара в местах его сбыта. Для расчёта объема
        производства в нашем случае нужно умножить урожайность на площадь
        тепличного комплекса.
      </p>
      <p className="text">
        Давайте рассчитаем доходы каждой линии производства:
      </p>
      <div className="page__list">
        <div className="page__list-item">
          <h2>Китайская</h2>
          <p className="text">
            Урожайность 130 кг / м<sup>2</sup> / год. <br /> <br />
            Как мы помним из задания площадь тепличного комплекса у всех
            производств одинакова и равна 12 000 м<sup>2</sup>
          </p>
          <p className="text">
            Значит объем производства за год равен{" "}
            <strong>
              130 кг / м<sup>2</sup> / год &times; 12 000 м<sup>2</sup> = 1 560
              000 кг
            </strong>
          </p>
          <p className="text">
            Теперь рассчитаем доходы:
            <br />
            На рынок острова мы можем отправить 1 200 00 кг, остальное пойдет на
            материк: <br /> Доходы с острова:{" "}
            <strong>1 200 000 кг. * 25 руб. кг. = 30 млн. руб. </strong>
            <br /> Доходы с материка:{" "}
            <strong> 360 000 кг. * 40 руб. кг. = 14,4 млн. руб.</strong>
          </p>
          <h3>Итого: 44,4 млн. руб. в год</h3>
        </div>
        <div className="page__list-item">
          <h2>Голландская</h2>
          <p className="text">
            Урожайность 300 кг / м<sup>2</sup> / год. <br /> <br />
            Объем производства за год равен{" "}
            <strong>
              300 кг / м<sup>2</sup> / год &times; 12 000 м<sup>2</sup> = 3 600
              000 кг
            </strong>
          </p>
          <p className="text">
            Доходы с острова:{" "}
            <strong>1 200 000 кг. * 25 руб. кг. = 30 млн. руб.</strong> <br />
            Доходы с материка:{" "}
            <strong> 2 400 000 кг. * 40 руб. кг. = 96 млн. руб.</strong>
          </p>
          <h3>Итого: 126 млн. руб. в год</h3>
        </div>
        <div className="page__list-item">
          <h2>Японская</h2>
          <p className="text">
            Урожайность 120 кг / м<sup>2</sup> / год. <br /> <br />
            Объем производства за год равен{" "}
            <strong>
              120 кг / м<sup>2</sup> / год &times; 12 000 м<sup>2</sup> = 1 440
              000 кг
            </strong>
          </p>
          <p className="text">
            Доходы с острова:{" "}
            <strong>1 200 000 кг. * 25 руб. кг. = 30 млн. руб.</strong> <br />
            Доходы с материка:{" "}
            <strong> 240 000 кг. * 40 руб. кг. = 9,6 млн. руб.</strong>
          </p>
          <h3>Итого: 39,6 млн. руб. в год</h3>
        </div>
      </div>
      <div className="page__footer">
        <button className="button_outlined" onClick={() => AppStore.goBack()}>
          Назад
        </button>
        <button className="button" onClick={() => AppStore.navigate(5)}>
          Далее
        </button>
      </div>
    </>
  );
};

export default observer(EducationThird);
