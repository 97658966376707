import React from "react";

const FailedIcon = ({ className }) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 0C22.3884 0 0 22.3884 0 50C0 77.6116 22.3884 100 50 100C77.6116 100 100 77.6116 100 50C100 22.3884 77.6116 0 50 0ZM68.4598 68.9955L61.0938 68.9621L50 55.7366L38.9174 68.9509L31.5402 68.9844C31.0491 68.9844 30.6473 68.5938 30.6473 68.0915C30.6473 67.8795 30.7254 67.6786 30.8594 67.5112L45.3795 50.2121L30.8594 32.9241C30.7245 32.7605 30.6497 32.5557 30.6473 32.3438C30.6473 31.8527 31.0491 31.4509 31.5402 31.4509L38.9174 31.4844L50 44.7098L61.0826 31.4955L68.4487 31.4621C68.9397 31.4621 69.3415 31.8527 69.3415 32.3549C69.3415 32.567 69.2634 32.7679 69.1295 32.9353L54.6317 50.2232L69.1406 67.5223C69.2746 67.6897 69.3527 67.8906 69.3527 68.1027C69.3527 68.5938 68.9509 68.9955 68.4598 68.9955Z"
        fill="#FF4D4F"
      />
    </svg>
  );
};

export default FailedIcon;
