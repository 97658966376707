import $api from "./axios";

export default {
  async get_progress() {
    return $api.get(`/progress/`);
  },
  async set_progress(progress) {
    return $api.post(`/progress/save/`, { progress });
  },
  async get_test_case() {
    return $api.get(`/case/`);
  },
  async check_answers(answers) {
    return $api.post(`/technologies/answers/check/`, answers);
  },
};
