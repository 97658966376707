import React, { useEffect, useState } from "react";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { notification } from "antd";

import { AppStore } from "./stores";

import { FinishPage, MainLayout } from "./ui";

function App() {
  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    AppStore.getProgress();
  }, []);

  useEffect(() => {
    const handleStatusChange = () => {
      setOnline(navigator.onLine);
      if (!navigator.onLine) {
        notification.error({
          message: "Нет соединения с интернетом",
          description:
            "Соединение с интернетом отсутствует. Прогресс не будет сохранен.",
          duration: 0,
          key: "internet",
        });
      } else {
        notification.destroy("internet");
      }
    };

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [online]);

  if (AppStore.finished != 0) {
    return <FinishPage />;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default observer(App);
