import { makeAutoObservable } from "mobx";
import api from "../api";

class AppStore {
  currentProgress = 1;
  currentPage = 1;
  animationState = null;
  answers = [];
  content = "";
  mostProfit = null;
  test = null;
  finished = 0;
  timeoutId = null;

  constructor() {
    makeAutoObservable(this);
  }

  setContent(content) {
    this.content = content;
  }

  setFinished(finished) {
    this.finished = finished;
  }

  setCurrentProgress(progress) {
    this.currentProgress = progress;
    this.setProgress(progress);
  }

  setCurrentPage(page) {
    this.currentPage = page;
  }

  setAnswers(answers) {
    this.answers = answers;
  }

  setMostProfit(mostProfitId) {
    this.mostProfit = mostProfitId;
  }

  setTest(test) {
    this.test = test;
  }

  setTimeoutId(timeoutId) {
    this.timeoutId = timeoutId;
  }

  setAnimationState(state) {
    this.animationState = state;
  }

  async getProgress() {
    await api.get_progress().then(({ data }) => {
      this.setCurrentPage(data.progress);
      this.setCurrentProgress(data.progress);
    });
  }

  setProgress(progress) {
    api.set_progress(progress);
  }

  navigate(to) {
    this.setAnimationState("leave");
    if (this.timeoutId !== null) clearTimeout(this.timeoutId);

    let timeout = setTimeout(() => {
      this.setCurrentPage(to);
      if (this.currentPage > this.currentProgress) {
        this.setCurrentProgress(to);
      }
      window.scrollTo(0, 0);
      this.setAnimationState("enter");
      setTimeout(() => {
        this.setAnimationState(null);
      }, 500);
    }, 600);
    this.setTimeoutId(timeout);
  }

  goBack() {
    if (this.currentPage > 1) {
      this.setAnimationState("leave_back");
      setTimeout(() => {
        this.setCurrentPage(this.currentPage - 1);
        window.scrollTo(0, 0);
        this.setAnimationState("enter_back");
        setTimeout(() => {
          this.setAnimationState(null);
        }, 500);
      }, 600);
    }
  }
}

let store = new AppStore();

export default store;
