import React from "react";

const Logo = ({ className }) => {
  return (
    <svg
      className={className}
      width="200"
      height="43"
      viewBox="0 0 200 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_49_316)">
        <path
          d="M88.948 2.53271V30.1262H82.3529V2.53271H88.948Z"
          fill="#283A97"
        />
        <path
          d="M119.299 2.53271V13.3126C120.032 12.6143 120.902 12.071 121.856 11.7161C122.811 11.3612 123.829 11.2024 124.848 11.2493C131.088 11.2493 133.841 16.2482 133.841 20.9704C133.841 25.6927 131.1 30.6796 124.86 30.6796C121.526 30.6796 119.47 29.1516 118.54 27.6297V30.1081H112.722V2.53271H119.299ZM123.061 16.5911C122.28 16.5886 121.513 16.8013 120.848 17.2053C120.183 17.6094 119.646 18.1884 119.299 18.8771V23.0879C119.648 23.7756 120.184 24.3539 120.849 24.7578C121.514 25.1616 122.28 25.3749 123.061 25.3738C125.851 25.3738 127.056 23.1241 127.056 20.9884C127.056 18.8529 125.864 16.5911 123.074 16.5911H123.061Z"
          fill="#283A97"
        />
        <path
          d="M154.698 11.8208V30.1261H148.885V28.0268C148.15 28.8658 147.239 29.5386 146.215 29.9996C145.19 30.4606 144.078 30.6986 142.95 30.6976C137.836 30.6976 135.505 27.2989 135.505 23.1843V11.8208H142.1V22.306C142.1 24.249 143.324 25.1694 144.926 25.1694C145.545 25.1506 146.151 24.9968 146.701 24.719C147.251 24.4411 147.733 24.0464 148.108 23.5633V11.8208H154.698Z"
          fill="#283A97"
        />
        <path
          d="M165.508 11.249C168.841 11.249 170.891 12.7769 171.828 14.2989V11.8205H177.639V29.3619C177.639 36.6828 172.249 39.0831 166.626 39.0831C163.855 39.1031 161.114 38.5176 158.6 37.3686L160.148 32.6223C161.916 33.3624 163.818 33.7449 165.74 33.7472C168.725 33.7472 171.05 32.8689 171.05 29.7409V28.6341C170.316 29.3274 169.446 29.8664 168.493 30.2182C167.54 30.5698 166.524 30.7267 165.508 30.6793C159.261 30.6793 156.508 25.6803 156.508 20.9522C156.508 16.2239 159.261 11.249 165.508 11.249ZM167.288 16.5908C164.498 16.5908 163.293 18.8407 163.293 20.9702C163.293 23.0997 164.516 25.3556 167.288 25.3556C168.069 25.3567 168.835 25.1433 169.5 24.7394C170.165 24.3356 170.701 23.7573 171.05 23.0696V18.8587C170.7 18.1736 170.163 17.5982 169.498 17.1974C168.833 16.7967 168.068 16.5867 167.288 16.5908Z"
          fill="#283A97"
        />
        <path
          d="M80.1809 26.1014L80.9212 29.9876C79.8536 30.3727 78.7242 30.5663 77.5869 30.5591C75.5313 30.5591 74.0203 29.8372 73.1637 28.5378C71.3352 30.0002 69.0418 30.7838 66.6849 30.7516C63.1182 30.7516 60.2061 28.6521 60.2061 25.1089C60.2061 20.4949 64.6659 19.0933 68.2756 19.0933C69.6306 19.0926 70.9818 19.2317 72.3072 19.5084V18.8588C72.3072 17.5594 71.0837 16.5668 68.4286 16.5668C66.5792 16.5948 64.7482 16.9326 63.0142 17.5654L61.8946 12.5304C64.2394 11.7873 66.6813 11.382 69.1443 11.3273C75.1031 11.3092 78.9207 13.3304 78.9207 19.8513V24.9646C78.9207 25.8308 79.5324 26.1014 80.1809 26.1014ZM69.2483 26.3662C70.3476 26.3963 71.4252 26.0614 72.3072 25.4157V22.9373C71.5402 22.7062 70.7419 22.5907 69.9396 22.5944C68.3918 22.5944 66.9969 23.1238 66.9969 24.5736C67.0031 25.5301 67.8534 26.3662 69.2483 26.3662Z"
          fill="#283A97"
        />
        <path
          d="M110.587 26.1014L111.346 29.9874C110.278 30.3719 109.149 30.5656 108.011 30.5589C105.956 30.5589 104.445 29.8371 103.588 28.5377C101.761 29.9988 99.4702 30.7823 97.1155 30.7514C93.5487 30.7514 90.6366 28.6519 90.6366 25.1088C90.6366 20.4949 95.0965 19.0933 98.7061 19.0933C100.061 19.0924 101.412 19.2316 102.738 19.5083V18.8587C102.738 17.5593 101.514 16.5667 98.859 16.5667C97.0157 16.5962 95.1911 16.9338 93.4631 17.5653L92.3435 12.5303C94.6881 11.7866 97.1301 11.3813 99.5932 11.3271C105.527 11.3271 109.327 13.3484 109.327 19.8632V24.9764C109.308 25.8307 109.932 26.1014 110.587 26.1014ZM99.6544 26.3661C100.754 26.3962 101.831 26.0613 102.713 25.4156V22.9372C101.946 22.7062 101.148 22.5906 100.346 22.5943C98.7979 22.5943 97.3969 23.1237 97.3969 24.5734C97.403 25.5299 98.2595 26.3661 99.6544 26.3661Z"
          fill="#283A97"
        />
        <path
          d="M199.26 26.1014L200 29.9874C198.933 30.3727 197.803 30.5662 196.665 30.5589C194.61 30.5589 193.099 29.8371 192.243 28.5377C190.415 29.9988 188.125 30.7823 185.77 30.7514C182.203 30.7514 179.291 28.6519 179.291 25.1088C179.291 20.4949 183.751 19.0933 187.36 19.0933C188.715 19.0924 190.066 19.2316 191.393 19.5083V18.8587C191.393 17.5593 190.169 16.5667 187.514 16.5667C185.67 16.5968 183.845 16.9344 182.118 17.5653L180.998 12.5303C183.343 11.7871 185.785 11.3819 188.248 11.3271C194.181 11.3271 197.981 13.3484 197.981 19.8632V24.9764C197.981 25.8307 198.605 26.1014 199.26 26.1014ZM188.328 26.3661C189.426 26.3962 190.504 26.0613 191.386 25.4156V22.9372C190.619 22.7062 189.82 22.5906 189.019 22.5943C187.47 22.5943 186.075 23.1237 186.075 24.5734C186.081 25.5299 186.933 26.3661 188.328 26.3661Z"
          fill="#283A97"
        />
        <path
          d="M31.9291 6.42462C29.1967 4.05899 26.2775 1.91069 23.1989 0C20.1222 1.91083 17.2051 4.05914 14.4747 6.42462C17.5525 8.15571 20.4715 10.1463 23.1989 12.374C25.9294 10.1476 28.8504 8.15703 31.9291 6.42462Z"
          fill="#283A97"
        />
        <path
          d="M38.0961 27.6237C40.7029 25.5572 43.4822 23.7098 46.4041 22.1015C44.6972 19.7915 42.8252 17.4815 40.8307 15.2197L40.3413 14.6782C39.1667 15.2918 37.9634 15.9896 36.7317 16.7717C35.0861 17.8183 33.4219 18.9975 31.7578 20.2908C32.5532 21.127 33.3486 21.9812 34.1316 22.8655C35.5143 24.4235 36.8357 26.0176 38.0961 27.6237Z"
          fill="#283A97"
        />
        <path
          d="M23.9272 12.9636C26.447 15.0529 28.8487 17.2761 31.122 19.6228C33.8046 17.4988 36.6705 15.6088 39.687 13.9742C37.469 11.5503 35.1204 9.24508 32.6514 7.06836C31.6786 7.59773 30.6876 8.18125 29.672 8.83092C27.6841 10.1076 25.7665 11.4871 23.9272 12.9636Z"
          fill="#283A97"
        />
        <path
          d="M14.6401 20.2908C12.9821 18.9975 11.3181 17.8183 9.67239 16.7717C8.44881 15.9896 7.22522 15.2918 6.06282 14.6782L5.5734 15.2197C3.56673 17.4815 1.70689 19.7915 0 22.1015C2.92196 23.7098 5.70114 25.5572 8.3081 27.6237C9.56839 26.0176 10.8899 24.4235 12.2725 22.8655C13.0556 21.9812 13.8448 21.127 14.6401 20.2908Z"
          fill="#283A97"
        />
        <path
          d="M28.0688 33.3502L27.8424 33.0855C27.7384 32.9712 27.6406 32.8509 27.5427 32.7366L27.2001 32.3396C27.1267 32.2554 27.0532 32.1651 26.9737 32.0809L26.3619 31.4071C25.6931 30.6572 25.0201 29.9214 24.3431 29.1995C24.3431 29.1995 24.3431 29.1995 24.3431 29.1694L23.8597 28.658C23.6456 28.4295 23.4253 28.2069 23.2051 27.9844C22.8197 28.3814 22.4281 28.7844 22.0427 29.1874C21.3616 29.9092 20.6886 30.6452 20.0238 31.3951L19.4119 32.0689L19.1917 32.3275L18.8492 32.7246C18.7452 32.8389 18.6472 32.9592 18.5493 33.0735L18.3231 33.3441C17.7907 33.9759 17.2707 34.6075 16.7629 35.2451C17.8561 36.364 18.9429 37.529 20.0238 38.7401L20.6356 39.4139L20.8742 39.6846L21.1984 40.0636L21.5166 40.4425L21.6939 40.6471C22.2078 41.2486 22.7157 41.8502 23.2112 42.4939C23.7128 41.8922 24.2207 41.2546 24.7346 40.6411L24.9058 40.4425L25.2301 40.0636L25.5543 39.6846L25.7929 39.4139L26.4047 38.7401C27.4814 37.537 28.5643 36.364 29.6656 35.2451C29.1149 34.6135 28.5949 33.9759 28.0688 33.3502Z"
          fill="#283A97"
        />
        <path
          d="M6.72974 13.9742C9.74625 15.6088 12.6121 17.4988 15.2947 19.6228C17.5625 17.2748 19.9602 15.0517 22.4772 12.9636C20.633 11.4853 18.7092 10.1057 16.7141 8.83092C15.7046 8.18726 14.7075 7.62781 13.7347 7.06836C11.2776 9.24737 8.93937 11.5525 6.72974 13.9742Z"
          fill="#283A97"
        />
      </g>
      <defs>
        <clipPath id="clip0_49_316">
          <rect width="200" height="42.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
