import React from "react";
import classnames from "classnames";
import { observer } from "mobx-react-lite";

import { AppStore } from "../../../stores";
import { routes } from "../../../routes";

import "./style.scss";

const AnimatedRoutes = () => {
  return (
    <div
      className={classnames("page", {
        enter: AppStore.animationState === "enter",
        leave: AppStore.animationState === "leave",
        enter_back: AppStore.animationState === "enter_back",
        leave_back: AppStore.animationState === "leave_back",
      })}
    >
      {routes[AppStore.currentPage].component}
    </div>
  );
};

export default observer(AnimatedRoutes);
