import React from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./style.css";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 38,
    }}
    spin
  />
);

const Loading = ({ wrapperNeeded = true }) => {
  return wrapperNeeded ? (
    <div className="loading__wrapper">
      <Spin indicator={antIcon} />
    </div>
  ) : (
    <Spin indicator={antIcon} />
  );
};

export default Loading;
