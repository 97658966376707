const env =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1" ||
  window.location.hostname === "dev.npv.alabuga.space" ||
  window.location.hostname === "npv.hr.alabuga.space"
    ? "DEV"
    :
  window.location.hostname === "npv.hr.alabuga.ru"
    ? "PROD"
    : "DEV";

const _URLS = {
  PROD: {
    URL: "https://api.npv.hr.alabuga.ru",
    AUTH: "https://auth.hr.alabuga.ru",
    PLATFORM: "https://hr.alabuga.ru/",
  },
  DEV: {
    URL: "https://api.dev.npv.alabuga.space",
    AUTH: "https://auth.dev.hr.alabuga.space",
    PLATFORM: "https://dev.hr.alabuga.space/",
  },
};

export const HOST = _URLS[env];
export const API_URL = HOST.URL + "/api";
export const AUTH_URL = HOST.AUTH;
export const PLATFORM_URL = HOST.PLATFORM;
