import convertNumToAbbr from "./convertNumToAbbr";

const prettierContentNums = (content) => {
  const nums = content.match(/(-?\d+(\.\d+)?)/g) || [];
  nums.map((number) => {
    if (+number >= 10000)
      content = content.replace(number, convertNumToAbbr(number, 2));
  });
  return content;
};

export default prettierContentNums;
