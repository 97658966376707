const getNumber = (value) => {
  const cleaned = value.replace(/[^\d-]/g, "");

  const isNegative = cleaned[0] === "-";

  return isNegative
    ? "-" + cleaned.substring(1).replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    : cleaned.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export default getNumber;
