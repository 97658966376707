import React from "react";
import { observer } from "mobx-react-lite";

import { AppStore } from "../../../stores";

import case_image from "../../../assets/media/education/case_image.png";

const EducationFirst = () => {
  return (
    <>
      <h1 className="title">Задание</h1>
      <p className="text">
        Давайте закрепим знания из видео и на примере увидим, как найти самую
        выгодную для инвестиций технологию.
      </p>
      <p className="text">
        Вы являетесь предпринимателем, который хочет организовать производство
        огурцов и построить тепличный комплекс.
      </p>
      <p className="text">
        Рассмотрим ситуацию, когда существует только 2 рынка огурцов: рынок
        острова и рынок материка. Логистика огурцов с материка на остров
        запредельно высока, поэтому для того, чтобы войти на рынок острова,
        необходимо построить там тепличный комплекс. Строительство на материке в
        условиях кейса не рассматривается, так как на материке уже существует
        необходимый объем производства.
      </p>
      <img src={case_image} alt="Фото для задания" className="page__image" />
      <p className="text">
        Площадь тепличного комплекса составляет 12 000 кв.м. Вам поставлена
        задача оценить перспективность этой бизнес идеи на основании следующей
        информации.
      </p>
      <p className="text">Доступны три технологии выращивания огурцов:</p>
      <div className="page__list">
        <div className="page__list-item">
          <h2>Китайская</h2>
          <p className="text">Урожайность 130 кг / кв. м / год.</p>
          <p className="text">
            Капитальные затраты формируются 2 статьями:
            <ul>
              <li>Конструкция и оборудование - 56 млн. руб.</li>
              <li>Строительные и монтажные работы – 24 млн руб.</li>
            </ul>
          </p>
          <p className="text">
            Операционные затраты:
            <ul>
              <li>
                Электричество. В соответствии с технологией, тепличный комплекс
                будет потреблять 4 млн КВт*час/год. Стоимость 1 КВт*час
                составляет 3 рубля.
              </li>
              <li>
                Оплата труда. Стоимость всех трудовых ресурсов составляет 500
                тыс. руб./мес.
              </li>
              <li>
                Прочие затраты (удобрения, семена и др) составляют 160 тыс.
                руб./мес.
              </li>
            </ul>
          </p>
        </div>
        <div className="page__list-item">
          <h2>Голландская</h2>
          <p className="text">Урожайность 300 кг / кв. м / год.</p>
          <p className="text">
            Капитальные затраты формируются 2 статьями:
            <ul>
              <li>Конструкция и оборудование - 84 млн руб.</li>
              <li>Строительные и монтажные работы – 36 млн руб.</li>
            </ul>
          </p>
          <p className="text">
            Операционные затраты:
            <ul>
              <li>
                Электричество. В соответствии с технологией, тепличный комплекс
                будет потреблять 8 млн КВт*час/год. Стоимость 1 КВт*час
                составляет 3 рубля.
              </li>
              <li>
                Оплата труда. Стоимость всех трудовых ресурсов составляет 1 млн
                руб/мес.
              </li>
              <li>
                Прочие затраты (удобрения, семена и др) составляют 300 тыс.
                руб./мес.
              </li>
            </ul>
          </p>
        </div>
        <div className="page__list-item">
          <h2>Японская</h2>
          <p className="text">Урожайность 120 кг / кв. м / год.</p>
          <p className="text">
            Капитальные затраты формируются 2 статьями:
            <ul>
              <li>Конструкция и оборудование - 30 млн. руб.</li>
              <li>Строительные и монтажные работы – 15 млн руб.</li>
            </ul>
          </p>
          <p className="text">
            Операционные затраты:
            <ul>
              <li>
                Электричество. В соответствии с технологией, тепличный комплекс
                будет потреблять 5 млн КВт*час/год. Стоимость 1 КВт*час
                составляет 3 рубля.
              </li>
              <li>
                Оплата труда. Стоимость всех трудовых ресурсов составляет 650
                тыс. руб./мес.
              </li>
              <li>
                Прочие затраты (удобрения, семена и др) составляют 240 тыс.
                руб./мес.
              </li>
            </ul>
          </p>
        </div>
      </div>
      <p className="text">
        Ваш тепличный комплекс производит максимально возможное количество
        огурцов. В первую очередь вы продаете товар на рынок острова, затем
        оставшееся количество продаете на рынок материка.
      </p>
      <h2 className="title">Задача:</h2>
      <p className="text">
        Построить структуру денежных потоков за период в 15 лет для каждой
        технологии и рассчитать показатели эффективности (EBITDA, NPV, ROI,
        PBP). По результатам расчетов требуется выбрать наиболее эффективную
        технологию по показателю NPV.
      </p>
      <div className="page__footer">
        <button className="button_outlined" onClick={() => AppStore.goBack()}>
          Назад
        </button>
        <button className="button" onClick={() => AppStore.navigate(3)}>
          Далее
        </button>
      </div>
    </>
  );
};

export default observer(EducationFirst);
