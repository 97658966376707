import React from "react";

import { AnimatedRoutes, Sidebar } from "../../../ui";
import "./style.scss";

const MainLayout = () => {
  return (
    <div className="main-layout">
      <Sidebar />
      <main className="main">
        <div className="main__container">
          <AnimatedRoutes />
        </div>
      </main>
    </div>
  );
};

export default MainLayout;
