import React from "react";

const SuccessIcon = ({ className }) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 0C22.3884 0 0 22.3884 0 50C0 77.6116 22.3884 100 50 100C77.6116 100 100 77.6116 100 50C100 22.3884 77.6116 0 50 0ZM71.596 33.6719L48.0915 66.2612C47.763 66.7197 47.3299 67.0933 46.8282 67.351C46.3265 67.6088 45.7705 67.7432 45.2065 67.7432C44.6424 67.7432 44.0865 67.6088 43.5847 67.351C43.083 67.0933 42.6499 66.7197 42.3214 66.2612L28.404 46.9754C27.9799 46.3839 28.404 45.558 29.1295 45.558H34.3638C35.5022 45.558 36.5848 46.1049 37.2545 47.0424L45.2009 58.0692L62.7455 33.7388C63.4152 32.8125 64.4866 32.2545 65.6362 32.2545H70.8705C71.596 32.2545 72.0201 33.0804 71.596 33.6719Z"
        fill="#52C41A"
      />
    </svg>
  );
};

export default SuccessIcon;
