import React from "react";

import {
  IntroductionPage,
  EducationFifth,
  EducationFirst,
  EducationSecond,
  EducationFourth,
  EducationThird,
  TestPage,
} from "./ui";

export const routes = {
  1: {
    component: <IntroductionPage />,
  },
  2: {
    component: <EducationFirst />,
  },
  3: {
    component: <EducationSecond />,
  },
  4: {
    component: <EducationThird />,
  },
  5: {
    component: <EducationFourth />,
  },
  6: {
    component: <EducationFifth />,
  },
  7: {
    component: <TestPage />,
  },
};
